class WTBAPI
	data = WTBAPI_Data

	call: (method, args, done) ->
		args = jQuery.extend({type: 'POST'}, data.data, args)
		args.method = method

		jQuery.ajax
			type: args.type
			url: data.ajaxUrl
			data: args
			success: (result) ->
				done result

	post: (method, args, done) ->
		@.call method, jQuery.extend({type: 'POST'}, args), done

	get: (method, args, done) ->
		@.call method, jQuery.extend({type: 'GET'}, args), done

	postForm: (method, args, done) ->
		args.append 'type', 'POST'
		args.append 'method', method

		jQuery.ajax
			type: 'POST'
			url: data.ajaxUrl
			data: args
			contentType: false
			processData: false 
			success: (result) ->
				done result